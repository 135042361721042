import './index.scss';
import React, { useEffect, useMemo, useState } from 'react';
import { setBreadcrumbs } from '../../../breadcrumbs';
import { useDispatch } from 'react-redux';
import {
  useGetProfileDetail,
  useIsLoadingProfileDetail
} from 'src/store/organization/selector';
import ActionButton from 'src/components/atoms/ActionButton';
import SettingsItem from 'src/components/molecules/SettingsItem';
import Skeleton from 'react-loading-skeleton';
import { AutoCashoutDetails } from '../../types';
import PageHeader from 'src/components/atoms/PageHeader';
import { useNavigationBlocker } from 'src/hooks/utils/useNavigationBlocker';
import { useUpdateCorpSettings } from '../../hooks/useUpdateCorpSettings';
import { setAutoCashoutSettings } from 'src/store/organization';
import ToastDisplay from 'src/components/atoms/ToastDisplay';
import AutoCashoutForm from '../../components/AutoCashoutForm';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LynkModal from 'src/components/atoms/LynkModal';

export const CorporateSettingsPage: React.FC = () => {
  const dispatch = useDispatch();
  const profile = useGetProfileDetail();
  const profileLoading = useIsLoadingProfileDetail();
  const { updateAutoCashoutSettings } = useUpdateCorpSettings();

  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showFeatureDisabledModal, setShowFeatureDisabledModal] =
    useState(false);
  const {
    values,
    setValues,
    setFieldValue,
    handleSubmit,
    touched,
    setTouched,
    errors
  } = useFormik<AutoCashoutDetails>({
    initialValues: {
      auto_cashout_enabled: profile.auto_cashout_enabled,
      frequency: 'Daily',
      frequency_time: profile.frequency_time || '',
      min_wallet_balance_percent:
        profile.min_wallet_balance_percent.toString() || ''
    },
    validationSchema: Yup.object().shape({
      frequency: Yup.string().required('This field is required'),
      frequency_time: Yup.string()
        .required('This field is required')
        .matches(/^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/, 'Enter a valid time'),
      min_wallet_balance_percent: Yup.number()
        .required('This field is required')
        .min(0, 'A value between 0 and 100 is required')
        .max(100, 'A value between 0 and 100 is required')
    }),
    onSubmit: values => {
      handleSaveSettings(values);
    }
  });

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        {
          name: profile.merchant,
          path: '/'
        },
        { name: 'Settings', path: '' }
      ])
    );
  }, []);

  const unsavedChanges = useMemo(() => {
    if (
      values.auto_cashout_enabled === profile.auto_cashout_enabled &&
      values.auto_cashout_enabled === false
    )
      return false;

    return (
      values.auto_cashout_enabled !== profile.auto_cashout_enabled ||
      values.min_wallet_balance_percent !==
        profile.min_wallet_balance_percent.toString() ||
      (profile.frequency !== undefined &&
        values.frequency.toLowerCase() !== profile.frequency?.toLowerCase()) ||
      (profile.frequency_time !== undefined &&
        values.frequency_time !== profile.frequency_time)
    );
  }, [values, profile]);

  const { ConfirmationModal } = useNavigationBlocker({
    title: 'You have unsaved changes',
    subtitle: (
      <>
        You are navigating away from the Settings page.
        <br /> If you do not save, you will lose all your changes.
      </>
    ),
    leaveBtnText: 'Discard Changes',
    stayBtnText: 'Keep Editing',
    blockingCondition: unsavedChanges
  });

  const toggleAutoCashout = () => {
    setFieldValue('auto_cashout_enabled', !values.auto_cashout_enabled);
  };

  const handleFormChange = (formDetails: Partial<AutoCashoutDetails>) => {
    setValues(prev => ({
      ...prev,
      ...formDetails
    }));
  };

  const handleSaveSettings = async (payload: AutoCashoutDetails) => {
    try {
      setIsSaving(true);
      await updateAutoCashoutSettings({
        ...payload,
        min_wallet_balance_percent: Number(payload.min_wallet_balance_percent)
      });
      dispatch(setAutoCashoutSettings(payload));
    } catch (error) {
      const status = (error as any).response?.status;
      if (status === 401) {
        setShowFeatureDisabledModal(true);
      } else {
        setErrorMessage(
          'An error occurred while saving settings, please contact support if it persists.'
        );
      }
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <>
      <div className="settingsPage">
        <PageHeader
          title="Settings"
          subtitle="Configure options you wish to use within the LynkBiz portal"
          action={
            <ActionButton
              type="primary"
              text="Save"
              loading={isSaving}
              classnames="saveButton"
              disabled={!unsavedChanges}
              onPress={handleSubmit}
            />
          }
        />

        <div className="notificationsBody">
          <div>
            <div className="preferenceTitle">Auto Cash Out</div>
            {profileLoading ? (
              <>
                <Skeleton height={40} width={'90%'} />
              </>
            ) : (
              <SettingsItem
                title="Automatically cash out to your bank account at a specified interval."
                active={values.auto_cashout_enabled}
                code="auto-cashout"
                handleChangeCallback={toggleAutoCashout}
                childStyle="childStyle"
              >
                {values.auto_cashout_enabled && (
                  <AutoCashoutForm
                    handleFormData={handleFormChange}
                    autoCashoutDetails={values}
                    setTouched={setTouched}
                    touched={touched}
                    errors={errors}
                  />
                )}
              </SettingsItem>
            )}
          </div>
        </div>
        {ConfirmationModal}
        {showFeatureDisabledModal && (
          <LynkModal
            title="Feature Unavailable"
            subTitle={
              <>
                You do not have any beneficiary accounts set up. <br />
                Please set up a beneficiary account for at least one of your
                sub-merchants before enabling auto cash out.
              </>
            }
            modalBody={<></>}
            show
            mainAction={{
              text: 'Okay',
              onClickFn: () => setShowFeatureDisabledModal(false),
              disabled: false,
              testID: ''
            }}
          />
        )}
        <ToastDisplay
          showDefaultIcon
          showToast={errorMessage !== ''}
          toastType="error"
          duration={5000}
          closeHandler={() => setErrorMessage('')}
          message={errorMessage}
        />
      </div>
    </>
  );
};
