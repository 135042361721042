import { ApiServiceType } from 'src/types/enums/apiServiceType';
import usePutData from 'src/hooks/api/usePutData';
import { AutoCashPayload } from '../types';

export const useUpdateCorpSettings = (): {
  updateAutoCashoutSettings: (payload: AutoCashPayload) => Promise<any>;
} => {
  const { putData } = usePutData();

  const updateAutoCashoutSettings = async (
    payload: AutoCashPayload
  ): Promise<any> => {
    const response = await putData({
      path: '',
      apiType: ApiServiceType.PROFILE,
      data: { ...payload }
    });

    return response;
  };

  return {
    updateAutoCashoutSettings
  };
};
