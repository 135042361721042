import React, { PropsWithChildren, useEffect, useState } from 'react';
import './index.scss';
import Switch from '../Switch';

interface SettingsCardProps {
  title: string;
  active: boolean;
  code: string;
  classes?: string;
  disabled?: boolean;
  handleChangeCallback: () => void;
  childStyle?: string;
}

const SettingsItem: React.FC<PropsWithChildren<SettingsCardProps>> = ({
  title,
  active,
  code,
  classes,
  disabled = false,
  handleChangeCallback,
  children,
  childStyle
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {screenWidth <= 485 ? (
        <div className="settingContainerMobile">
          <div className="cardTopSectionMobile">
            <div className="titleMobile">{title}</div>
            <div className="moreDetailsIconMobile">
              <Switch
                id={code}
                activeColour="#055AEE"
                checked={active}
                disabled={disabled}
                handleToggle={handleChangeCallback}
              />
            </div>
          </div>
          {children && (
            <div className={`${childStyle} childMobile`}>{children}</div>
          )}
        </div>
      ) : (
        <>
          <div className={`settingsCardContainer ${classes}`}>
            <div className="cardTitleContainer">
              <div className="uniqueSettingGroup">
                <div className="title">{title}</div>
                {children && <div className={`${childStyle}`}>{children}</div>}
              </div>
              <div className="moreDetailsIcon">
                <Switch
                  id={code}
                  activeColour="#055AEE"
                  checked={active}
                  disabled={disabled}
                  handleToggle={handleChangeCallback}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SettingsItem;
