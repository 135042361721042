import './index.scss';

import {
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label
} from 'reactstrap';
import React, { useState } from 'react';

import NumberFormat from 'react-number-format';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export interface CurrencyTextInputProps {
  amount: string;
  onChange: (value: string) => void;
  label?: string;
  invalid?: boolean;
  feedback?: string;
  testId?: string;
  nameInput?: string;
  onBlurInput?: () => void;
  onFocusInput?: () => void;
  disabled?: boolean;
  leftInputTextLabel?: string;
  rightInputTextLabel?: string;
  placeholder?: string;
  decimalScale?: number;
  customClass?: string;
}

const CurrencyTextInput: React.FC<CurrencyTextInputProps> = ({
  amount,
  onChange,
  label = 'Send Amount',
  invalid,
  feedback,
  testId = 'currencyTextInput',
  nameInput,
  onBlurInput,
  disabled,
  onFocusInput,
  leftInputTextLabel = '$',
  rightInputTextLabel = '',
  placeholder = '0.00',
  decimalScale = 2,
  customClass = ''
}) => {
  const [inputTouched, setInputTouched] = useState(false);

  const [isActive, setIsActive] = useState(false);
  const onBlurHandler = () => {
    setIsActive(false);
    if (!inputTouched) setInputTouched(true);
    onBlurInput?.();
  };

  const onFocusHandler = () => {
    setIsActive(true);
    onFocusInput?.();
  };
  return (
    <FormGroup className="currency-text-input">
      {label && (
        <Label
          data-testid="currencyTextLabel"
          className={`currency-text-input__label 
        ${isActive ? 'active' : ''} 
        ${invalid && !isActive ? 'invalid' : ''}`}
        >
          {label}
        </Label>
      )}
      <InputGroup className={`${customClass}`}>
        {leftInputTextLabel && (
          <InputGroupText
            data-testid="currencyTextIconWrapper"
            className={`currency-text-input__simbol-wrapper 
            ${rightInputTextLabel ? 'with-left-icon' : ''}
         ${isActive ? 'active' : ''} 
         ${invalid && !isActive ? 'invalid' : ''} ${disabled && 'disabled'}`}
          >
            {invalid && !isActive ? (
              <ErrorOutlineIcon className="currency-text-input__icon" />
            ) : (
              leftInputTextLabel
            )}
          </InputGroupText>
        )}
        <NumberFormat
          name={nameInput}
          value={amount}
          customInput={Input}
          thousandSeparator={true}
          className={`currency-text-input__input-amount ${
            rightInputTextLabel ? 'with-right-icon' : ''
          } ${leftInputTextLabel ? 'with-left-icon' : ''} ${
            disabled && 'disabled'
          }`}
          onValueChange={({ value }) => onChange(value)}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          decimalScale={decimalScale}
          data-testid={testId}
          placeholder={placeholder}
          invalid={invalid && !isActive}
          disabled={disabled}
        />
        {rightInputTextLabel && (
          <InputGroupText
            data-testid="currencyTextIconWrapper"
            className={`currency-text-input__simbol-wrapper 
          ${rightInputTextLabel ? 'with-right-icon' : ''}
         ${isActive ? 'active' : ''} 
         ${invalid && !isActive ? 'invalid' : ''} ${disabled && 'disabled'}`}
          >
            {invalid && !isActive ? (
              <ErrorOutlineIcon className="currency-text-input__icon" />
            ) : (
              rightInputTextLabel
            )}
          </InputGroupText>
        )}
        <FormFeedback>{feedback}</FormFeedback>
      </InputGroup>
    </FormGroup>
  );
};

export default CurrencyTextInput;
