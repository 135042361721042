/* eslint-disable @typescript-eslint/no-explicit-any */
import './index.scss';

import React, { useEffect, useState } from 'react';

import RoutingTabs from '../../molecules/RoutingTabs';
import { NotificationsTemplateProps } from '../../../types/models/singlePage.Type';
import useUpdateNotification from '../../../hooks/notifications/useUpdateNotification';
import { useGetScreenState } from '../../../store/screenSize/selector';
import { TransactionStateKeyEnum } from '../../../hooks/utils/tableConfig/transactionState';
import { useGetTransactionTableState } from '../../../store/transactions/tableState/selector';
import { SortingAscDesc } from '../../../types/models/utils/table-models';
import useGetPaginationInformation from '../../../hooks/utils/useGetPaginationInformation';
import PageHeader from 'src/components/atoms/PageHeader';

const Notifications: React.FC<NotificationsTemplateProps> = ({ menu }) => {
  const { readAllNotification } = useUpdateNotification();
  const transactionTableState = useGetTransactionTableState();
  const { dispatchMetadataUpdate } = useGetPaginationInformation();

  const { breakpoint } = useGetScreenState();
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(breakpoint === 'smartphone' ? true : false);
  }, [breakpoint]);
  const markAllRead = async () => {
    try {
      const response = await readAllNotification();
      const newSortOrder =
        transactionTableState[TransactionStateKeyEnum.NOTIFICATIONS].sort ===
        SortingAscDesc.DESC
          ? SortingAscDesc.ASC
          : SortingAscDesc.DESC;

      dispatchMetadataUpdate(
        { sort: newSortOrder },
        TransactionStateKeyEnum.NOTIFICATIONS
      );
    } catch (error) {
      console.log(error);
    }
  };
  const actionButton = (
    <div id="readButton" className="readButton" onClick={markAllRead}>
      Mark All As Read
    </div>
  );
  return (
    <>
      <PageHeader
        title="Notifications History"
        subtitle="Keep track of all your notifications here. Get a clear overview of
        important updates, and alerts you might have missed."
      />
      {!isMobile && actionButton}

      <RoutingTabs
        menuTabs={menu}
        className="noBackground"
        actionElement={isMobile ? actionButton : undefined}
      />
    </>
  );
};

export default Notifications;
