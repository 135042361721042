import { getIconMapper, IconKey } from '../../../utils/iconMap';
import React, { useEffect, useMemo, useState } from 'react';
import './index.scss';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Skeleton from 'react-loading-skeleton';

export interface SelectorItemProp {
  icon?: IconKey;
  label: string;
  value: string | number;
  key: string;
}

export interface FormattedSelectorProps {
  label?: string;
  activeItem?: string | number;
  options: SelectorItemProp[];
  loading: boolean;
  onSelect: (value: { selectedValue: string | number; key: string }) => void;
  disabled: boolean;
  defaultSelectedItem: SelectorItemProp;
  handleBlur?: () => void;
  feedback?: string;
  invalid?: boolean;
  restrictListHeight?: boolean;
}

interface SelectorItemPropBody extends SelectorItemProp {
  loading: boolean;
}

const FormattedSelectorItem: React.FC<SelectorItemPropBody> = ({
  icon,
  label,
  value,
  loading
}) => {
  let Icon = <></>;

  if (icon) {
    Icon = getIconMapper(icon);
  }

  return (
    <>
      {loading ? (
        <div className="selectorLoader">
          <Skeleton
            className="iconLoading"
            width={35}
            height={35}
            baseColor="#D9D9D9"
          />
          <div className="detailsLoadingGroup">
            <Skeleton width={165} height={10} baseColor="#D9D9D9" />
            <Skeleton width={121} height={10} baseColor="#D9D9D9" />
          </div>
        </div>
      ) : (
        <div className="selectorBaseInner">
          {icon && <div className="selectorBaseInner--icon">{Icon}</div>}
          <div className="selectorBaseInner--text">{label}</div>
        </div>
      )}
    </>
  );
};

const FormattedSelector: React.FC<FormattedSelectorProps> = ({
  label,
  activeItem,
  options,
  onSelect,
  loading,
  disabled,
  defaultSelectedItem,
  handleBlur,
  feedback,
  invalid,
  restrictListHeight = false
}) => {
  const activeSelectedItem = useMemo(() => {
    if (activeItem) {
      return (
        options.find(option => option.value == activeItem) ||
        defaultSelectedItem
      );
    }

    return defaultSelectedItem;
  }, [activeItem, options]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!loading && !options.length) {
      options.push({
        key: '',
        label: 'No items found',
        value: ''
      });
    }
  }, [loading, options]);

  return (
    <>
      {loading ? (
        <Skeleton
          width={141}
          height={15}
          baseColor="#D9D9D9"
          className="titleLoading"
        />
      ) : (
        <div className={`selectorTitle ${isOpen ? 'active' : 'inactive'}`}>
          {label}
        </div>
      )}
      <UncontrolledDropdown
        onBlur={handleBlur}
        data-testid="formatterSelectorID"
        onToggle={() => setIsOpen(!isOpen)}
        className={`boxSelector ${
          isOpen ? 'active' : invalid ? 'error' : 'inactive'
        } ${loading ? 'disabled' : ''}`}
      >
        <DropdownToggle outline caret size="lg" className="selectorBase">
          <FormattedSelectorItem
            loading={loading}
            icon={activeSelectedItem.icon as IconKey}
            label={activeSelectedItem.label}
            value={activeSelectedItem.value}
            key={activeSelectedItem.key}
          />
          {isOpen ? (
            <KeyboardArrowUpIcon className="active" />
          ) : (
            <KeyboardArrowDownIcon className="inactive" />
          )}
        </DropdownToggle>
        {options.length >= 1 && !loading ? (
          <DropdownMenu
            className={`selectorBaseMenu ${
              restrictListHeight ? 'restrictListHeight' : ''
            }`}
          >
            {options.map(option => (
              <>
                <DropdownItem
                  key={option.value}
                  className="selectorBaseMenu--Item"
                  data-testid="formatterOption"
                  onClick={() => {
                    if (!option.value) return;
                    onSelect({
                      selectedValue: option.value,
                      key: option.key
                    });
                  }}
                >
                  <FormattedSelectorItem
                    key={option.value.toString()}
                    loading={false}
                    icon={option.icon}
                    label={option.label}
                    value={option.value}
                  />
                </DropdownItem>
              </>
            ))}
          </DropdownMenu>
        ) : (
          <></>
        )}
      </UncontrolledDropdown>
      <small className="error mt-1">{feedback}</small>
    </>
  );
};

export default FormattedSelector;
