import LynkModal from 'src/components/atoms/LynkModal';
import { useBlocker } from 'react-router-dom';

interface Props {
  title: string;
  subtitle: string | JSX.Element;
  leaveBtnText: string;
  stayBtnText: string;
  blockingCondition: boolean;
}

/**
 * Custom hook that creates a navigation blocker using react-router-dom.

 * @param {string} title - The title of the confirmation modal.
 * @param {string | JSX.Element} subtitle - The subtitle of the confirmation modal.
 * @param {string} leaveBtnText - The text for the leave button.
 * @param {string} stayBtnText - The text for the stay button.
 * @param {boolean} blockingCondition - The condition that determines whether the navigation should be blocked.
 * @returns {Object} - An object containing the ConfirmationModal JSX element.
 */
export const useNavigationBlocker = ({
  title,
  subtitle,
  leaveBtnText,
  stayBtnText,
  blockingCondition
}: Props): {
  ConfirmationModal: JSX.Element | null;
} => {
  const navigationBlocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      blockingCondition && nextLocation.pathname !== currentLocation.pathname
  );

  const ConfirmationModal =
    navigationBlocker.state === 'blocked' ? (
      <LynkModal
        title={title}
        subTitle={subtitle}
        modalBody={<></>}
        show
        mainAction={{
          text: stayBtnText,
          onClickFn: navigationBlocker.reset,
          disabled: false,
          testID: ''
        }}
        closeAction={{
          text: leaveBtnText,
          onClickFn: navigationBlocker.proceed,
          disabled: false,
          testID: ''
        }}
      />
    ) : null;

  return { ConfirmationModal };
};
