import './index.scss';
import React, { useState } from 'react';
import { AutoCashoutDetails } from '../../types';
import { Dropdown, DropdownToggle } from 'reactstrap';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import InputWithButtons from '../../../../components/atoms/InputWithButton';
import ButtonTooltip from '../../../../components/atoms/ButtonTooltip';
import NumericInput from 'src/components/atoms/CurrencyTextInput';
import { FormikErrors, FormikTouched } from 'formik';

interface AutoCashOutFormProps {
  autoCashoutDetails: AutoCashoutDetails;
  handleFormData: (data: Partial<AutoCashoutDetails>) => void;
  setTouched: (
    touched: FormikTouched<AutoCashoutDetails>,
    shouldValidate?: boolean | undefined
  ) => Promise<void> | Promise<FormikErrors<AutoCashoutDetails>>;
  touched: FormikTouched<AutoCashoutDetails>;
  errors: FormikErrors<AutoCashoutDetails>;
}

const AutoCashoutForm: React.FC<AutoCashOutFormProps> = ({
  handleFormData,
  autoCashoutDetails,
  touched,
  setTouched,
  errors
}) => {
  const [isDropDownDisabled] = useState<boolean>(true);
  const [isButtonActive, setIsButtonActive] = useState({
    text: autoCashoutDetails.frequency_time
      ? autoCashoutDetails.frequency_time.slice(-2)
      : 'AM'
  });
  const [timePeriod, setTimePeriod] = useState(
    autoCashoutDetails.frequency_time
      ? autoCashoutDetails.frequency_time.slice(-2)
      : 'AM'
  );
  const [inputValue, setinputValue] = useState(
    autoCashoutDetails.frequency_time.slice(0, -3)
  );

  const formattedTimeInput = (timeValue: string) => {
    const time = timeValue.replace(/[^\d]/g, '');
    const timeValueLength = time.length;
    if (timeValueLength === 0) return '';
    if (timeValueLength <= 2) return time;
    if (timeValueLength < 4) {
      return `${time.slice(0, 1)}:${time.slice(1, 3)}`;
    }
    return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
  };

  const handleTimeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedTime = formattedTimeInput(e.target.value);
    setinputValue(formattedTime);
  };

  const handleTimeButtonPress = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (e.currentTarget.innerText === 'PM') {
      setTimePeriod(e.currentTarget.innerText);
      setIsButtonActive({ text: e.currentTarget.innerText });
    } else {
      setTimePeriod('AM');
      setIsButtonActive({ text: e.currentTarget.innerText });
    }
  };

  return (
    <div className="autoCashoutContainer">
      <div className="cashoutMinBalanceContainer">
        <div className="tooltipContainer">
          <span
            className={`label ${
              touched.min_wallet_balance_percent &&
              errors.min_wallet_balance_percent &&
              'error'
            }`}
          >
            Remaining Balance Percentage
          </span>
          <ButtonTooltip
            tooltipIdentifier={'min-balance-tooltip'}
            title={''}
            feedback={
              'The percentage of your balance that should remain in the wallet after an auto cash out.'
            }
            withErrors={true}
            iconStyle="cashOutTooltipStyle"
          />
        </div>
        <div className="currencyFieldReset">
          <NumericInput
            leftInputTextLabel=""
            rightInputTextLabel="%"
            label=""
            placeholder="0"
            invalid={
              touched.min_wallet_balance_percent &&
              !!errors.min_wallet_balance_percent
            }
            onBlurInput={() =>
              setTouched({ ...touched, min_wallet_balance_percent: true })
            }
            decimalScale={0}
            customClass="minBalanceInput"
            amount={autoCashoutDetails.min_wallet_balance_percent.toString()}
            onChange={val =>
              handleFormData({
                min_wallet_balance_percent: val
              })
            }
            feedback={
              touched.min_wallet_balance_percent &&
              errors.min_wallet_balance_percent
                ? errors.min_wallet_balance_percent
                : ''
            }
          />
        </div>
      </div>

      <div className="frequencyContainer">
        <div className="frequencyDropdown">
          <span className="label">Frequency</span>
          <Dropdown
            className={isDropDownDisabled ? 'disabled' : 'dropdownStyle'}
            disabled={isDropDownDisabled}
          >
            <DropdownToggle caret className="dropdownTextStyle">
              Daily
              <KeyboardArrowDownOutlinedIcon className="dropdownIcon" />
            </DropdownToggle>
          </Dropdown>
        </div>

        <div className="timeContainer">
          <div className="tooltipContainer">
            <span
              className={`label ${
                touched.frequency_time && errors.frequency_time && 'error'
              }`}
            >
              Time
            </span>
            <ButtonTooltip
              tooltipIdentifier={'ACH-tooltip'}
              title={''}
              feedback={
                'Each bank has a daily ACH cut off time. We recommend using a time before your bank’s ACH daily cut off time to ensure the money reflects in your bank account same business day.'
              }
              withErrors={true}
              iconStyle="cashOutTooltipStyle"
            />
          </div>
          <InputWithButtons
            buttonOneText="AM"
            buttonTwoText="PM"
            placeholder="HH:MM"
            isButtonActive={isButtonActive}
            onBlur={() => setTouched({ ...touched, frequency_time: true })}
            invalid={touched.frequency_time && !!errors.frequency_time}
            onChangeFn={event => {
              const newValue = event.target?.value;
              if (newValue !== undefined && handleFormData !== undefined) {
                const formattedTime = formattedTimeInput(newValue);
                handleFormData({
                  frequency_time: `${formattedTime} ${timePeriod}`
                });
                handleTimeInput(event);
              }
            }}
            inputValue={inputValue}
            maxLength={5}
            onClickFn={e => {
              const newPeriodValue = e.currentTarget?.innerText;
              if (e.target !== undefined && handleFormData !== undefined) {
                handleFormData({
                  frequency_time: `${inputValue} ${newPeriodValue}`
                });
                handleTimeButtonPress(e);
              }
            }}
          />
          {touched.frequency_time && errors.frequency_time && (
            <small className="error">{errors.frequency_time}</small>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoCashoutForm;
