import './index.scss';
import React from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';

interface ActiveButtonProps {
  text?: string;
}

interface InputWithButtonProps {
  buttonOneText: string;
  buttonTwoText?: string;
  placeholder?: string;
  buttonGroupStyle?: string;
  buttonStyle?: string;
  inputValue: string | undefined;
  onChangeFn: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onClickFn: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onBlur?: () => void;
  maxLength?: number;
  isButtonActive?: ActiveButtonProps;
  classes?: string;
  invalid?: boolean;
}

const InputWithButtons: React.FC<InputWithButtonProps> = ({
  buttonOneText,
  buttonTwoText,
  placeholder,
  buttonGroupStyle,
  buttonStyle,
  onChangeFn,
  onBlur,
  inputValue,
  maxLength,
  isButtonActive,
  onClickFn,
  invalid = false,
  classes
}) => {
  return (
    <div className={`inputWrapper ${classes}`}>
      <Input
        onBlur={onBlur}
        placeholder={placeholder}
        onChange={onChangeFn}
        value={inputValue}
        maxLength={maxLength}
        className={`${invalid && 'invalid'}`}
      />
      <ButtonGroup className={`buttonGroup ${buttonGroupStyle}`}>
        <Button
          className={`btnStyle ${buttonStyle}`}
          active={isButtonActive?.text === buttonOneText}
          onClick={onClickFn}
          style={{ marginRight: '3px' }}
        >
          {buttonOneText}
        </Button>
        <Button
          className={`btnStyle ${buttonStyle}`}
          active={isButtonActive?.text === buttonTwoText}
          onClick={onClickFn}
        >
          {buttonTwoText}
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default InputWithButtons;
