import './index.scss';

import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import React, { useEffect, useState } from 'react';
import {
  useGetProfileDetail,
  useIsLoadingProfileDetail
} from '../../../store/organization/selector';

import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Skeleton from 'react-loading-skeleton';
import useDashboardSetup from '../../../hooks/permissions/useDashboardSetup';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../store/actions';
import { useGetProfileState } from '../../../store/profile/selector';
import { toTitleCase } from '../../../utils/textFormatter';

interface Props {
  displayName?: string;
  role: string;
  loading: boolean;
  dropdown: boolean;
}

export const ProfileMenuLabel: React.FC<Props> = ({
  displayName,
  role,
  loading,
  dropdown
}) => {
  const loadingLabel = (
    <Skeleton
      className={'profile-label__loading'}
      containerTestId="profile-loading"
      width={150}
    />
  );

  const loadedLabel = (
    <div className={'profile-label__active'}>
      <div data-testid="username" style={{ fontSize: 15 }}>
        {`${displayName ? displayName + ' | ' : ''} ${toTitleCase(role)}`}
      </div>
      {dropdown && <KeyboardArrowDownOutlinedIcon />}
    </div>
  );

  return loading ? loadingLabel : loadedLabel;
};

const ProfileMenu: React.FC = () => {
  const dispatch = useDispatch();

  const [menu, setMenu] = useState(false);
  const [validComponents, setValidComponents] = useState<any[]>([]);

  const fetching = useIsLoadingProfileDetail();
  const { role, displayName } = useGetProfileState();
  const { organization_id } = useGetProfileDetail();
  const { getValidProfileListing } = useDashboardSetup();

  const isNotCorporatePage =
    location.pathname.split('/')[1] === organization_id.slice(4);

  useEffect(() => {
    const listing = getValidProfileListing();
    setValidComponents(listing);
  }, []);

  const logoutUser = () => {
    dispatch(
      showModal({
        modalKey: 'logout-user'
      })
    );
  };

  return (
    <React.Fragment>
      <Dropdown
        data-testid="profileDropdown"
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="profileContainer d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          data-testid="profileDropdownClicker"
          tag="button"
        >
          <ProfileMenuLabel
            displayName={displayName}
            role={role}
            loading={fetching}
            dropdown={true}
          />
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-end"
          data-testid="profileDropdownMenu"
        >
          {validComponents
            .filter(
              item =>
                (isNotCorporatePage && item.key !== 'corporate-settings') ||
                !isNotCorporatePage
            )
            .map(function (menuItem, i) {
              if (menuItem.key !== 'logout') {
                return (
                  <Link
                    key={menuItem.key}
                    to={menuItem.url}
                    className="dropdown-item"
                  >
                    {menuItem.icon}
                    <div>{menuItem.display}</div>
                  </Link>
                );
              } else {
                return (
                  <div key={i} className="dropdown-item" onClick={logoutUser}>
                    {menuItem.icon}
                    <div>{menuItem.display}</div>
                  </div>
                );
              }
            })}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
