import React from 'react';
import './index.scss';

interface PageHeaderProps {
  title: string;
  subtitle: string;
  action?: React.ReactNode;
  children?: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, action }) => {
  return (
    <header className="pageHeader">
      <div className="header">
        <div>
          <h2 className="title">{title}</h2>
          <p className="subtitle">{subtitle}</p>
        </div>
        <div className="pageHeader-action">{action && action}</div>
      </div>
    </header>
  );
};

export default PageHeader;
