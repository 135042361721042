import { AutoCashoutDetails } from 'src/features/corporateSettings';
import { MerchantDetails } from '../../types/models/merchantDetails';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface OrganizationState {
  error: unknown;
  loading: boolean;
  profile: MerchantDetails;
  role: string;
  balance: number;
  balanceJAMDEX: number;
  parent_id: string;
}

const initialState: OrganizationState = {
  role: '',
  error: null,
  loading: false,
  parent_id: '',
  balance: 0,
  balanceJAMDEX: 0,
  profile: {
    qr_code: '',
    parent_name: '',
    merchant: '',
    about: '',
    category: '',
    phone: '',
    address: '',
    email: '',
    username: '',
    organization_id: '',
    parent_org_id: '',
    id: '',
    low_balance_threshold: 0,
    low_balance_enabled: true,
    auto_cashout_enabled: false,
    frequency: '',
    frequency_time: '',
    min_wallet_balance_percent: 0
  }
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    loadProfile: state => {
      state.loading = true;
      state.error = null;
      state.profile = initialState.profile;
    },
    loadProfileSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
      state.profile = action.payload;
    },
    loadProfileFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.profile = initialState.profile;
    },
    setParentID: (state, action) => {
      state.parent_id = action.payload;
    },
    addBalance: (state, action) => {
      state.balance += action.payload;
    },
    addJAMDEXBalance: (state, action) => {
      state.balanceJAMDEX += action.payload;
    },
    restartBalance: state => {
      state.balance = 0;
      state.balanceJAMDEX = 0;
    },
    setLowBalanceThreshold: (state, action: PayloadAction<number>) => {
      state.profile.low_balance_threshold = action.payload;
    },
    setAutoCashoutSettings: (
      state,
      action: PayloadAction<AutoCashoutDetails>
    ) => {
      const {
        auto_cashout_enabled,
        frequency,
        frequency_time,
        min_wallet_balance_percent
      } = action.payload;
      state.profile.auto_cashout_enabled = auto_cashout_enabled;
      state.profile.frequency = frequency;
      state.profile.frequency_time = frequency_time;
      state.profile.min_wallet_balance_percent = Number(
        min_wallet_balance_percent
      );
    }
  }
});

export const {
  loadProfile,
  loadProfileSuccess,
  loadProfileFailure,
  setParentID,
  addBalance,
  restartBalance,
  addJAMDEXBalance,
  setLowBalanceThreshold,
  setAutoCashoutSettings
} = organizationSlice.actions;

export default organizationSlice.reducer;
