import './index.scss';

import React, { useEffect, useState } from 'react';
import Navbar from '../../molecules/HorizontalNavbar';
import Header from '../../molecules/HorizontalHeader';
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import { Outlet, useLocation } from 'react-router-dom';

import ModalHandler from '../modals/ModalHandler';
import ToastDisplay from '../../atoms/ToastDisplay';
import { AlertMetadata } from '../../../store/state';
import { useGetAppStatuses } from '../../../store/status/selector';
import { popAlertMetadata } from '../../../store/status';
import { useDispatch } from 'react-redux';
import { Breadcrumbs } from '../../../features/breadcrumbs';
import { useGetProfileDetail } from '../../../store/organization/selector';
import ButtonAsyncLoader from '../../atoms/ButtonAsyncLoader';
import { usePermissions } from '../../../features/permissions';
import AccessDenied from '../../../components/pages/AccessDenied';

const Layout: React.FC<any> = props => {
  const profile = useGetProfileDetail();
  const { userPermissions: permissions } = usePermissions();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const openMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const [currentToaster, setCurrentToaster] = useState<
    AlertMetadata | undefined
  >(undefined);

  const alerts = useGetAppStatuses();
  const { merchant: merchantName, organization_id } = useGetProfileDetail();
  const dispatch = useDispatch();

  useEffect(() => {
    const generalAlerts = alerts.filter(alert => alert.scope === 'general');
    if (currentToaster === undefined) {
      setCurrentToaster(generalAlerts[0]);
    } else if (generalAlerts.length === 0) setCurrentToaster(undefined);
  }, [alerts]);

  const closer = (key: string) => {
    dispatch(popAlertMetadata(key));
    setCurrentToaster(undefined);
  };

  const isNotCorporatePage =
    location.pathname.split('/')[1] === organization_id.slice(4);

  return (
    <React.Fragment>
      <div id="layout-wrapper" data-testid="horizontalLayoutCont">
        <header id="page-topbar">
          <Header
            isMenuOpened={isMenuOpened}
            openLeftMenuCallBack={openMenu}
          ></Header>
          <div className="d-block d-md-none">
            <Navbar
              menuOpen={isMenuOpened}
              closeMenu={() => setIsMenuOpened(false)}
            />
          </div>
        </header>
        <div className="main-content">
          <div className="page-content">
            {profile.organization_id &&
            profile.organization_id != '' &&
            permissions.size !== 0 ? (
              <Breadcrumbs />
            ) : permissions.size === 0 ? (
              <AccessDenied />
            ) : (
              <ButtonAsyncLoader showLoaderText={true} />
            )}
            {isNotCorporatePage && (
              <h3 className="branch-name">{merchantName}</h3>
            )}

            <Outlet />
          </div>
        </div>
      </div>
      <ModalHandler />
      <div id="download-comp"></div>
      {currentToaster && (
        <ToastDisplay
          showDefaultIcon={currentToaster.alertDetails.icon ? false : true}
          classnames="general-toast"
          showToast={true}
          icon={currentToaster.alertDetails.icon}
          message={currentToaster.alertDetails.message}
          closeHandler={() => closer(currentToaster.key)}
          toastType={currentToaster.alertType}
          onOutsideClick={() => closer(currentToaster.key)}
        />
      )}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayout: PropTypes.func,
  changeLayoutWidth: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any
};

export default Layout;
